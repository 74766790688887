"use strict";Object.defineProperty(exports, "__esModule", {value: true});// src/token-stakers-arbitrum-one.ts
var tokenStakersArbitrumOne = [
  {
    address: "0xba93D7107f6F2682aF4135d1989657300A465687",
    token: "0x9C0385b4F1f3B277ab352B817fC56763081a503c",
    tokens: [
      "0x9C0385b4F1f3B277ab352B817fC56763081a503c",
      "0x2A5E22b32b3E0Daa7a8C199e10Df9D9E1264Fd3f"
    ]
  },
  {
    address: "0x8B7754Bd5a91F4Ca1CC314e089E462B825e5775f",
    token: "0x4bB0Eb07a8ECDcF5f434095Aa34Cc3f69292bcA1",
    tokens: [
      "0x4bB0Eb07a8ECDcF5f434095Aa34Cc3f69292bcA1",
      "0x2A5E22b32b3E0Daa7a8C199e10Df9D9E1264Fd3f"
    ]
  },
  {
    address: "0x8237D9049F3B55F87f8132b82e66591670a89c53",
    token: "0xDB0aEb568EfE3598e9A58407c8b52BcFaC2c11e5",
    tokens: [
      "0xDB0aEb568EfE3598e9A58407c8b52BcFaC2c11e5",
      "0x2A5E22b32b3E0Daa7a8C199e10Df9D9E1264Fd3f"
    ]
  }
];



exports.tokenStakersArbitrumOne = tokenStakersArbitrumOne;
