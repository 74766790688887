"use strict";Object.defineProperty(exports, "__esModule", {value: true});// src/farming-stakers-arbitrum-one.ts
var farmingStakersArbitrumOne = [
  {
    address: "0x7aFfdD28D244FeBfB4A6e8db14D5aA42AB8396Ce",
    pool: "0xAfb2aaA7b90905f32fDb3E61010F0dc2705827ca",
    tokens: [
      "0x9C0385b4F1f3B277ab352B817fC56763081a503c",
      "0x2A5E22b32b3E0Daa7a8C199e10Df9D9E1264Fd3f"
    ]
  },
  {
    address: "0x0a84C2f54E3C7A00eb57922eEDB03440429E123b",
    pool: "0xaFFEBBcbd0DD5AEDeCCdAB84b4103828780fD972",
    tokens: [
      "0x4bB0Eb07a8ECDcF5f434095Aa34Cc3f69292bcA1",
      "0x2A5E22b32b3E0Daa7a8C199e10Df9D9E1264Fd3f"
    ]
  },
  {
    address: "0xFA8c04138407756dDAe054287df603b3aed39662",
    pool: "0xe24dB13D645218672D4D5Fc15f572328b32946A4",
    tokens: [
      "0xDB0aEb568EfE3598e9A58407c8b52BcFaC2c11e5",
      "0x2A5E22b32b3E0Daa7a8C199e10Df9D9E1264Fd3f"
    ]
  }
];



exports.farmingStakersArbitrumOne = farmingStakersArbitrumOne;
