"use strict";Object.defineProperty(exports, "__esModule", {value: true});

var _chunkU65Z74PSjs = require('./chunk-U65Z74PS.js');


var _chunkJPIMBK6Vjs = require('./chunk-JPIMBK6V.js');


var _chunk53PNDNBKjs = require('./chunk-53PNDNBK.js');


var _chunk33ZWW7ENjs = require('./chunk-33ZWW7EN.js');


var _chunkEW7YDBMRjs = require('./chunk-EW7YDBMR.js');


var _chunkI7IO6ZUEjs = require('./chunk-I7IO6ZUE.js');

// src/addresses.ts
var farmingStakers = {
  42161: _chunkEW7YDBMRjs.farmingStakersArbitrumOne,
  421614: _chunkI7IO6ZUEjs.farmingStakersArbitrumSepolia
};
var tokenStakers = {
  42161: _chunkU65Z74PSjs.tokenStakersArbitrumOne,
  421614: _chunkJPIMBK6Vjs.tokenStakersArbitrumSepolia
};
var boostingStakers = {
  42161: _chunk53PNDNBKjs.boostingStakersArbitrumOne,
  421614: _chunk33ZWW7ENjs.boostingStakersArbitrumSepolia
};
var farmingStakersAddresses = {
  42161: {
    tbb: "0x7aFfdD28D244FeBfB4A6e8db14D5aA42AB8396Ce",
    tbs: "0x0a84C2f54E3C7A00eb57922eEDB03440429E123b",
    tbg: "0xFA8c04138407756dDAe054287df603b3aed39662"
  },
  421614: {
    tbb: "0x2f6a5149Ab827c527324DdB82647b2EbBBcdE9bE",
    tbs: "0x533D6bF2C2249e4604f483192e8b21e90400e123",
    tbg: "0xb0BC2754605219F8201b0d5b34fc0340b34057F2"
  }
};
var tokenStakerAddresses = {
  42161: {
    tbb: "0xba93D7107f6F2682aF4135d1989657300A465687",
    tbs: "0x8B7754Bd5a91F4Ca1CC314e089E462B825e5775f",
    tbg: "0x8237D9049F3B55F87f8132b82e66591670a89c53"
  },
  421614: {
    tbb: "0x016393363A48D8319225429eB744EdaeC922f097",
    tbs: "0x1746A0d1d4A1260CDA9Dd865d68fD109A335E19f",
    tbg: "0x09e092b5dC4cE5D3459D865b0bf19a003204df13"
  }
};
var boosterAddresses = {
  42161: {
    tbb: "0xd5A7399301A850e1F66EFDbf6e2120B0c4671C1b",
    tbs: "0xdC0C9dc2DF304644973224F91c1D65dCe3bba3fc",
    tbg: "0x5Ab512c0207f7852373a04da9B079F27a55254BF"
  },
  421614: {
    tbb: "0xaAfaD1620193FB26A66810638459BB1cCBF6d7FD",
    tbs: "0x3246BB27e990934C65d6DC07C5F5aBCF8C4242AB",
    tbg: "0xB1bBE2154d17d3c7beC11aDB90FAab1Cf38855a0"
  }
};
var tokenDistributor = {
  42161: "0x70520d9BF8FE4E9eE4aCEaE6168B629961AF0A11",
  421614: "0x7F80B08dDCE1737a6957C279d33EfAFCB354912C"
};
var rewardSplitter = {
  42161: "0x4953B478214Bb4F4691765e6FD6dEE6690b2E7B6",
  421614: "0xe1Fb360EfED2c5B3FB0b1Fb345E1149113f2033e"
};
var farmingSplitter = {
  42161: "0xa7B8062e45519AFEa6D4aBdB77CF47da8cD81230",
  421614: "0x4B54A0823a18F1131a35876fACCA0767eF897eFa"
};
var stakingSplitter = {
  42161: "0xD3C6e8895bCCF20875a93D05187c249968d717Ee",
  421614: "0x8aEBb2623a5b85F848D2C7c57185d1DbceF283E1"
};
var boostingSplitter = {
  42161: "0x23C51664EAe50A7594c31790b1562494FA6a6E54",
  421614: "0x4fbEb3702e41ceC4e6a79490A89b6Fc21216e1b3"
};
var teamSplitter = {
  42161: "0xF0076e130C077DAEE73EF2244260f4051A986671",
  421614: "0x47e626D66F19F8DC3E5376de5b17398fcCdfca8E"
};














exports.farmingStakers = farmingStakers; exports.tokenStakers = tokenStakers; exports.boostingStakers = boostingStakers; exports.farmingStakersAddresses = farmingStakersAddresses; exports.tokenStakerAddresses = tokenStakerAddresses; exports.boosterAddresses = boosterAddresses; exports.tokenDistributor = tokenDistributor; exports.rewardSplitter = rewardSplitter; exports.farmingSplitter = farmingSplitter; exports.stakingSplitter = stakingSplitter; exports.boostingSplitter = boostingSplitter; exports.teamSplitter = teamSplitter;
