"use strict";Object.defineProperty(exports, "__esModule", {value: true});// src/boosting-stakers-arbitrum-one.ts
var boostingStakersArbitrumOne = [
  {
    address: "0xd5A7399301A850e1F66EFDbf6e2120B0c4671C1b",
    token: "0x2A5E22b32b3E0Daa7a8C199e10Df9D9E1264Fd3f",
    staker: "0x3b966493605F0E4830a494bbFE16359056F935BB"
  },
  {
    address: "0xdC0C9dc2DF304644973224F91c1D65dCe3bba3fc",
    token: "0x2A5E22b32b3E0Daa7a8C199e10Df9D9E1264Fd3f",
    staker: "0xa91e98f955F7F6800fF3ae0eBb41cdE4f4343460"
  },
  {
    address: "0x5Ab512c0207f7852373a04da9B079F27a55254BF",
    token: "0x2A5E22b32b3E0Daa7a8C199e10Df9D9E1264Fd3f",
    staker: "0x2c1903A7B091cde7Df3fc463918100AE9f5d7571"
  }
];



exports.boostingStakersArbitrumOne = boostingStakersArbitrumOne;
