"use strict";Object.defineProperty(exports, "__esModule", {value: true});// src/referrals.ts
var referrals = {
  42161: "0x0",
  421614: "0x81F024a6c3cAe41039F95d9bE81543E45E8e68E6"
};
var referralsRewards = {
  421614: "0xE62CC836d3eF0B5F31A8934CC9929b124CdC21F4"
};




exports.referrals = referrals; exports.referralsRewards = referralsRewards;
