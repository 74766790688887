"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  AddToMetamaskButton: () => AddToMetamaskButton,
  BaseButton: () => BaseButton,
  Col: () => Col,
  Row: () => Row
});
module.exports = __toCommonJS(src_exports);

// src/col.ts
var import_styled_components = __toESM(require("styled-components"));
var import_styled_system = require("styled-system");
var Col = import_styled_components.default.div`
  display: flex;
  flex-direction: column;

  ${import_styled_system.flexbox}
  ${import_styled_system.width}
  ${import_styled_system.maxWidth}
  ${import_styled_system.minWidth}
  ${import_styled_system.minHeight}
  ${import_styled_system.height}
  ${import_styled_system.space}
  ${import_styled_system.background}
  ${import_styled_system.display}
  ${import_styled_system.textAlign}
`;

// src/row.ts
var import_styled_components2 = __toESM(require("styled-components"));
var import_styled_system2 = require("styled-system");
var Row = import_styled_components2.default.div`
  display: flex;

  ${import_styled_system2.background}
  ${import_styled_system2.display}
  ${import_styled_system2.flexbox}
  ${import_styled_system2.height}
  ${import_styled_system2.justifyContent}
  ${import_styled_system2.maxWidth}
  ${import_styled_system2.space}
  ${import_styled_system2.textAlign}
  ${import_styled_system2.width}
`;

// src/button/AddToMetamaskButton.ts
var import_styled_components4 = __toESM(require("styled-components"));

// src/button/BaseButton.ts
var import_styled_components3 = __toESM(require("styled-components"));
var BaseButton = import_styled_components3.default.button`
  align-items: center;
  border-radius: 16px;
  border: 1px solid transparent;
  color: white;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  font-weight: 535;
  justify-content: center;
  line-height: 24px;
  outline: none;
  padding: 16px 6px;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: initial;
  z-index: 1;

  &:disabled {
    opacity: 50%;
    cursor: auto;
    pointer-events: none;
  }

  will-change: transform;
  transition: transform 450ms ease;
  transform: perspective(1px) translateZ(0);

  > * {
    user-select: none;
  }

  > a {
    text-decoration: none;
  }
`;

// src/button/AddToMetamaskButton.ts
var AddToMetamaskButton = (0, import_styled_components4.default)(BaseButton)`
  width: fit-content;
  background-color: transparent;
  padding: 6px 12px;
  border: 1px solid white;
  margin-top: 6px;
  font-weight: normal;
  
  &:focus {
    outline: none;
  }
`;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  AddToMetamaskButton,
  BaseButton,
  Col,
  Row
});
