"use strict";Object.defineProperty(exports, "__esModule", {value: true});// src/ipfs.ts
var pinata = {
  logoLdt: "QmZfY8UwFxfTNxXGt3Zn9xvXWJSbgGyfmkdX7X7mLCa78Q",
  logoLira: "QmYbNDEnsZktRu9j9xHfCTtYig7P9XkRgwZ2Nv9EiCHCnW",
  logoTb: "QmXdXZZL6ReTv5112xr88UhZHg4bj8TZqxFHiYu84WrmsR"
};



exports.pinata = pinata;
