"use strict";Object.defineProperty(exports, "__esModule", {value: true});// src/token-stakers-arbitrum-sepolia.ts
var tokenStakersArbitrumSepolia = [
  {
    address: "0x016393363A48D8319225429eB744EdaeC922f097",
    token: "0xb81B1310037Fb2866B267792C2537ced143d3DE8",
    tokens: [
      "0xb81B1310037Fb2866B267792C2537ced143d3DE8",
      "0x62F53E68662B013ea03B7BA6803b624632179eD3"
    ]
  },
  {
    address: "0x1746A0d1d4A1260CDA9Dd865d68fD109A335E19f",
    token: "0xeE388b955fEb0567Cf86dFAAcdf393aEC14184aa",
    tokens: [
      "0xeE388b955fEb0567Cf86dFAAcdf393aEC14184aa",
      "0x62F53E68662B013ea03B7BA6803b624632179eD3"
    ]
  },
  {
    address: "0x09e092b5dC4cE5D3459D865b0bf19a003204df13",
    token: "0x34597dc215fcfd707c4B9b2D4ba4f69f45A91A82",
    tokens: [
      "0x34597dc215fcfd707c4B9b2D4ba4f69f45A91A82",
      "0x62F53E68662B013ea03B7BA6803b624632179eD3"
    ]
  }
];



exports.tokenStakersArbitrumSepolia = tokenStakersArbitrumSepolia;
