"use strict";Object.defineProperty(exports, "__esModule", {value: true});// src/farming-stakers-arbitrum-sepolia.ts
var farmingStakersArbitrumSepolia = [
  {
    address: "0x2f6a5149Ab827c527324DdB82647b2EbBBcdE9bE",
    pool: "0x63cB832062De3C0B86aeDdcC41A54AA8DAcae7D6",
    tokens: [
      "0xb81B1310037Fb2866B267792C2537ced143d3DE8",
      "0x62F53E68662B013ea03B7BA6803b624632179eD3"
    ]
  },
  {
    address: "0x533D6bF2C2249e4604f483192e8b21e90400e123",
    pool: "0x58D8C1b01c2B9BFc74B0425f8CA3180b9C70De4C",
    tokens: [
      "0xeE388b955fEb0567Cf86dFAAcdf393aEC14184aa",
      "0x62F53E68662B013ea03B7BA6803b624632179eD3"
    ]
  },
  {
    address: "0xb0BC2754605219F8201b0d5b34fc0340b34057F2",
    pool: "0x7BAbb27A79A3f470bEea87A9008Fc22681F42e2c",
    tokens: [
      "0x34597dc215fcfd707c4B9b2D4ba4f69f45A91A82",
      "0x62F53E68662B013ea03B7BA6803b624632179eD3"
    ]
  }
];



exports.farmingStakersArbitrumSepolia = farmingStakersArbitrumSepolia;
