"use strict";Object.defineProperty(exports, "__esModule", {value: true});// src/boosting-stakers-arbitrum-sepolia.ts
var boostingStakersArbitrumSepolia = [
  {
    address: "0xaAfaD1620193FB26A66810638459BB1cCBF6d7FD",
    token: "0x62F53E68662B013ea03B7BA6803b624632179eD3",
    staker: "0x6D32D83943D8bFC906de6cf7a8BAEdA4Fecb1133"
  },
  {
    address: "0x3246BB27e990934C65d6DC07C5F5aBCF8C4242AB",
    token: "0x62F53E68662B013ea03B7BA6803b624632179eD3",
    staker: "0x0191bfD1f254Da13eb9328DF7Faf7878b0364AA9"
  },
  {
    address: "0xB1bBE2154d17d3c7beC11aDB90FAab1Cf38855a0",
    token: "0x62F53E68662B013ea03B7BA6803b624632179eD3",
    staker: "0xC7F6572dacc910957Fe78BA76B125620cDe680f0"
  }
];



exports.boostingStakersArbitrumSepolia = boostingStakersArbitrumSepolia;
